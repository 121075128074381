const isElementVisible = el => {
  const efp = (x, y) => document.elementFromPoint(x, y);
  const { bottom, left, right, top } = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  if (right < 0 || bottom < 0 || left > vWidth || top > vHeight) return false;

  return [
    [left, top],
    [right, top],
    [right, bottom],
    [left, bottom],
  ].some(destination => el.contains(efp(...destination)));
};

export default isElementVisible;
