export const isExist = (value, inner) => {
  if (inner) {
    return value && !!value[`${inner}`].length;
  } else {
    return value && !!value.length;
  }
};

export const generateCalendarLink = ({ title, description, date_start, date_end, location }) => {
  const esc = encodeURIComponent;

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${esc(title)}&details=${esc(
    description,
  )}&location=${esc(location)}&dates=${date_start}/${date_end}`;
};

export const generateCommentLink = (id, link) => {
  if (link) {
    const { type, slug } = link;

    switch (type) {
      case 'article':
        return `/articles/${slug}/?comment=${id}`;
      default:
        break;
    }
  }

  return '/';
};

export const ensureRouteName = route =>
  typeof route === 'object' && route !== null ? route.name : route;

let timerId;
export const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);

  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};
