import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames/bind';

// Components
import HomeContentDesktop from './HomeContentDesktop';
import HomeContentTablet from './HomeContentTablet';
import HomeContentPhone from './HomeContentPhone';

import useUnreservePromos from 'hooks/useUnreservePromos';

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

const CARDS_ON_PAGE_COUNT = 9;

function HomeContent({ promos, paginationHref, changePage }) {
  const page = useSelector(state => state.home.page);
  const content = useSelector(state => state.home.content);
  const articlesData = useSelector(state => state.home.articles);
  const sidebar = useSelector(state => state.home.sidebar);
  const isLoading = useSelector(state => state.home.isLoadingArticles);

  const isServer = useSelector(state => state.responsive.isServer);
  const isOnlyDesktop = useSelector(state => state.responsive.isOnlyDesktop);
  const isNotebook = useSelector(state => state.responsive.isNotebook);
  const isPhone = useSelector(state => state.responsive.isPhone);
  const unreservePromos = useUnreservePromos();

  const articles = useMemo(
    () => ({
      ...articlesData,
      page,
      total: Math.ceil(articlesData.total / CARDS_ON_PAGE_COUNT),
    }),
    [articlesData, page],
  );

  const ContentComponent = useMemo(() => {
    unreservePromos();

    return isPhone
      ? HomeContentPhone
      : (isServer && isOnlyDesktop) || isNotebook
      ? HomeContentTablet
      : HomeContentDesktop;
  }, [unreservePromos, isPhone, isServer, isOnlyDesktop, isNotebook]);

  return (
    <div className={cx('HomeContent')}>
      <ContentComponent
        isLoading={isLoading}
        page={page}
        articles={articles}
        content={content}
        sidebar={sidebar}
        promos={promos}
        paginationHref={paginationHref}
        changePage={changePage}
      />
    </div>
  );
}

HomeContent.propTypes = {
  promos: PropTypes.array,
  paginationHref: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

HomeContent.defaultProps = {
  promos: [],
};

export default HomeContent;
