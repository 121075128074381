// Vendor
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isExist } from 'utils/helpers';

// Config
import { partners as config } from 'config/xsolla.json';

// Components
import AppLink from 'components/AppLink';
import Button from 'components/Button';
import Image from 'components/Image';

import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const OurPartners = ({ className, action, partners, items }) => {
  const { elementClick } = useAnalytics();

  const handleGoToVendors = useCallback(() => {
    elementClick('80lv_site_vendors_click', 'all', { value: 'our-partners' });
  }, [elementClick]);

  const handleItemClick = useCallback(() => {
    elementClick('80lv_site_vendors_click', 'one', { value: 'our-partners' });
  }, [elementClick]);

  return (
    <div className={cn(styles.OurPartners, className)}>
      <ul className={styles.OurPartners__list}>
        {isExist(items) &&
          items.map(item => (
            <li key={item.id} className={styles.OurPartners__listItem}>
              <AppLink
                href={`/partners/${item.slug}`}
                className={styles.OurPartners__listLink}
                title={item.title}
                onClick={handleItemClick}
              >
                <Image image={item.image} />
              </AppLink>
            </li>
          ))}
      </ul>

      <div className={styles.OurPartners__actions}>
        <Button
          href="/partners"
          className={styles.OurPartners__action}
          preset="primary"
          theme="none"
          size="medium"
          withArrow
          onClick={handleGoToVendors}
        >
          {action}
        </Button>

        <Button
          href={config.mailto}
          className={cn(styles.OurPartners__action, styles.OurPartners__action_mailto)}
          theme="none"
          preset="tertiary"
          size="medium"
          withArrow
        >
          {partners}
        </Button>
      </div>
    </div>
  );
};

OurPartners.defaultProps = {
  className: '',
  action: 'More partners',
  partners: 'Become a partner',
};

OurPartners.propTypes = {
  className: PropTypes.string,
  action: PropTypes.string,
  partners: PropTypes.string,
  items: PropTypes.array,
};

export default OurPartners;
