import React from 'react';

const LogoutIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 96 96" {...props}>
    <g>
      <path
        fill="currentColor"
        d="M 0.5,-0.5 C 21.1667,-0.5 41.8333,-0.5 62.5,-0.5C 62.9867,0.473444 63.4867,1.47344 64,2.5C 64.1667,7.5 64.3333,12.5 64.5,17.5C 64.6215,23.2727 64.1215,28.9394 63,34.5C 59.6063,36.2747 56.9396,35.608 55,32.5C 54.5003,24.5069 54.3336,16.5069 54.5,8.5C 39.1667,8.5 23.8333,8.5 8.5,8.5C 8.5,34.5 8.5,60.5 8.5,86.5C 23.8333,86.5 39.1667,86.5 54.5,86.5C 54.3336,78.4931 54.5003,70.4931 55,62.5C 56.9396,59.392 59.6063,58.7253 63,60.5C 64.1215,66.0606 64.6215,71.7273 64.5,77.5C 64.3333,82.5 64.1667,87.5 64,92.5C 63.4867,93.5266 62.9867,94.5266 62.5,95.5C 41.8333,95.5 21.1667,95.5 0.5,95.5C 0.5,94.8333 0.166667,94.5 -0.5,94.5C -0.5,63.1667 -0.5,31.8333 -0.5,0.5C 0.166667,0.5 0.5,0.166667 0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        fill="currentColor"
        d="M 95.5,44.5 C 95.5,46.5 95.5,48.5 95.5,50.5C 89.1517,56.848 82.485,62.848 75.5,68.5C 71.7455,67.0968 70.5789,64.4302 72,60.5C 74.5,58 77,55.5 79.5,53C 63.1667,52.6667 46.8333,52.3333 30.5,52C 25.1667,49 25.1667,46 30.5,43C 46.8333,42.6667 63.1667,42.3333 79.5,42C 77,39.5 74.5,37 72,34.5C 70.5789,30.5698 71.7455,27.9032 75.5,26.5C 82.485,32.152 89.1517,38.152 95.5,44.5 Z"
      />
    </g>
  </svg>
);

export default LogoutIcon;
