import React from 'react';

const BookmarkEmptyIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.68339 2H18.315V4.10526H5.68339V2ZM7.78865 17.7895V19.8947H5.68339V22H3.57812V4.10526H5.68339V17.7895H7.78865ZM9.89391 15.6842V17.7895L7.78865 17.7895V15.6842H9.89391ZM11.9992 13.5789H9.89391L9.89391 15.6842L11.9992 15.6842L14.1044 15.6842V17.7895L16.2097 17.7895V19.8947H18.315L18.315 22H20.4202V4.10526H18.315V17.7895H16.2097L16.2097 15.6842H14.1044V13.5789H11.9992Z"
      fill="currentColor"
    />
  </svg>
);

export default BookmarkEmptyIcon;
