import React from 'react';

import styles from './styles.styl';

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className={styles.Button__arrow} viewBox="0 0 8 14">
    <defs />
    <path
      fillRule="evenodd"
      d="M.293 7.707a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L2.414 7l5.293 5.293a1 1 0 11-1.414 1.414l-6-6z"
      clipRule="evenodd"
    />
  </svg>
);

export default Arrow;
