import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames/bind';

// Components
import Page from 'components/Page';
import Container from 'components/Container';
import FeaturedArticle from 'components/widgets/FeaturedArticle';

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

function HomeHeader() {
  const items = useSelector(state => state.home.header);

  return items && items.length ? (
    <Container>
      <Page.Section className={cx('HomeHeader')}>
        <FeaturedArticle items={items} />
      </Page.Section>
    </Container>
  ) : null;
}

export default HomeHeader;
