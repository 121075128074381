// Vendor
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'next/router';
import cookie from 'js-cookie';

// Components
import Page from 'components/Page';
import { HomeHeader, HomePartners, HomeContent } from 'components/pages/Home';

// Actions
import { loadArticles, loadPage, loadPageIfNotLoaded, setSliderType } from 'state/home/actions';

// Custom Hooks
import usePromos from 'hooks/usePromos';
import useTheme from 'hooks/useTheme';

// Styles
import styles from './styles.styl';

const REQURED_PROMOS = {
  header: { 'big-image': 1 },
  content: { image: 2 },
  footer: { image: 2 },
};

const HomePage = () => {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const page = useSelector(state => state.home.page);
  const meta = useSelector(state => state.home.meta);
  const promo = useSelector(state => state.home.promo);
  const sliders = useSelector(state => state.home.sliders);
  const setTheme = useTheme().setTheme;
  const updatePromos = usePromos(promo, null, REQURED_PROMOS); // Load banners

  /** Changes page
   * @param {number} page
   */
  const changePage = newPage => {
    dispatch(loadPage(newPage));
  };

  // URL for links in a pagination
  const setPaginationHref = useCallback(() => `/`, []);

  useEffect(() => {
    return () => {
      const getSliderType = defaultValue => {
        const sliderTypes = sliders.map(item => item.type);

        if (!defaultValue || !sliderTypes.includes(defaultValue)) {
          return sliderTypes[0];
        }

        const currentIndex = sliderTypes.indexOf(defaultValue);

        return sliderTypes[(currentIndex + 1) % sliderTypes.length];
      };

      const sliderType = getSliderType(cookie.get('sliderType'));

      if (!sliderType) {
        return;
      }

      cookie.set('sliderType', sliderType);
      dispatch(setSliderType(sliderType));
    };
  }, [dispatch, sliders]);

  // Changes page and scorll to a section with anchor
  useEffect(() => {
    const anchor = containerRef.current
      ? containerRef.current.querySelector('[data-anchor="articles"]')
      : null;

    dispatch(loadArticles(page));
    updatePromos();
    window.scroll({
      top: anchor ? anchor.offsetTop - 50 : 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [dispatch, page, updatePromos]);

  // Changes theme
  useEffect(() => {
    setTheme({ theme: 'default', bgImage: null, bgColor: null });
  }, [setTheme]);

  return (
    <Page className={styles.HomePage} meta={meta}>
      <div ref={containerRef} className={styles.HomePage__container}>
        <HomeHeader />
        <HomeContent
          promos={promo.content}
          paginationHref={setPaginationHref}
          changePage={changePage}
        />
        <HomePartners />
      </div>
    </Page>
  );
};

HomePage.propTypes = {
  router: PropTypes.object.isRequired,
};

HomePage.getInitialProps = async ({ store, res, req }) => {
  const {
    data: { content = [] },
  } = await store.dispatch(loadPageIfNotLoaded(1));

  const slider = content.find(({ type }) => type === 'slider');

  if (!slider) {
    return;
  }

  const getSliderType = defaultValue => {
    const sliderTypes = slider.content.map(item => item.type);

    if (!defaultValue || !sliderTypes.includes(defaultValue)) {
      return sliderTypes[0];
    }

    const currentIndex = sliderTypes.indexOf(defaultValue);

    return sliderTypes[(currentIndex + 1) % sliderTypes.length];
  };

  const sliderType = getSliderType(req.cookies['sliderType']);

  if (!sliderType) {
    return;
  }

  res.setHeader('set-cookie', `sliderType=${sliderType}`);
  await store.dispatch(setSliderType(sliderType));
};

export default withRouter(HomePage);
