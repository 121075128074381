import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useAuth from 'features/auth/hooks/useAuth';
import useLogin from 'features/auth/hooks/useLogin';
import useAnalytics from 'hooks/useAnalytics';
import styles from './styles.styl';
import LogoutIcon from 'ui/icons/LogoutIcon';
import { useSelector } from 'react-redux';
function Auth({ className, children, action, label, logoutButton }) {
  const { isAuthenticated, logout } = useAuth();
  const { showFullscreenWidget } = useLogin();
  const { elementClick } = useAnalytics();
  const { isOnlyDesktop, isTablet } = useSelector(state => state.responsive);

  return (
    <div className={cn(styles.Auth, className)}>
      {isAuthenticated && logoutButton ? (
        <button type="button" className={cn(styles.Auth__link)} onClick={logout}>
          {(!isOnlyDesktop || isTablet) && <LogoutIcon />}
          <span className={cn(styles.Auth__linkTag)}>Log out</span>
        </button>
      ) : isAuthenticated ? null : (
        children || (
          <Fragment>
            {label && <span className={styles.Auth__label}>{label}</span>}
            <button
              type="button"
              className={cn(styles.Auth__link, 'xl-login')}
              onClick={showFullscreenWidget}
            >
              <span
                className={styles.Auth__linkText}
                onClick={() => elementClick('80lv_site_login-widget_open')}
              >
                {action || 'Log in / Sign up'}
              </span>
              <img
                src="/static/icons/arrow.svg"
                alt="click_arrow"
                className={styles.Auth__linkArrow}
              />
            </button>
          </Fragment>
        )
      )}
    </div>
  );
}

Auth.defaultProps = {
  className: '',
  logoutButton: true,
};

Auth.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  action: PropTypes.string,
  label: PropTypes.string,
  logoutButton: PropTypes.bool,
};

export default Auth;
