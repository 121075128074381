import React, { Fragment } from 'react';
import dynamic from 'next/dynamic';

import { Routes80lv } from 'config/routes';

import useModal from 'hooks/useModal';

import Button from 'components/Button';

import styles from './styles.styl';

const BuyJobPostModal = dynamic(
  () => import('components/BuyJobPostModal').then(component => component.BuyJobPostModal),
  { loading: () => <></> },
);

const FeaturedJobsActions = () => {
  const { openModal, closeModal, isOpen, Modal: ModalContainer } = useModal();

  return (
    <Fragment>
      <Button
        className={styles.FeaturedJobsActions__button}
        isLink
        href={Routes80lv.JobBoard.pattern}
        theme="dark"
      >
        See all jobs
      </Button>
      <Button className={styles.FeaturedJobsActions__button} theme="dark" onClick={openModal}>
        Post a job
      </Button>
      {isOpen && (
        <ModalContainer>
          <BuyJobPostModal onClose={closeModal} />
        </ModalContainer>
      )}
    </Fragment>
  );
};

export default FeaturedJobsActions;
