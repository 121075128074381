import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import htmlParser from 'html-react-parser';
import { generateCommentLink } from 'utils/helpers';

import Like from 'static/icons/comments/like.svg';

// Components
import AppLink from 'components/AppLink';

import styles from './styles.styl';

const Discussion = ({ className, id, comment, lastname, name, author_name, karma, anchor }) => {
  const author = `${lastname || ''} ${name || ''}`.trim() || author_name || 'Anonymous user';
  return (
    <AppLink
      href={generateCommentLink(id, anchor)}
      as={`/articles/${anchor.slug}`}
      className={cn(styles.Discussion, className)}
    >
      <p className={styles.Discussion__title}>{anchor.title}</p>
      <div className={styles.Discussion__comment}>{htmlParser(comment)}</div>
      <div className={styles.Discussion__footer}>
        <button
          className={cn(styles.Discussion__button, styles.Discussion__button_like, {
            [styles.Discussion__button_active]: karma > 0,
          })}
        >
          <Like className={styles.Discussion__icon} />
          <span className={styles.Discussion__buttonText}>{karma}</span>
        </button>
        <span className={styles.Discussion__point}>·</span>
        <p className={styles.Discussion__writerName} title={author}>
          {author}
        </p>
      </div>
    </AppLink>
  );
};

Discussion.defaultProps = {
  className: '',
  anchor: {
    title: '',
  },
};

Discussion.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  comment: PropTypes.string.isRequired,
  name: PropTypes.string,
  lastname: PropTypes.string,
  author_name: PropTypes.string,
  karma: PropTypes.number,
  anchor: PropTypes.object,
};

export default Discussion;
