import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isExist } from 'utils/helpers';

// Components
import { Typography } from 'ui/Typography';
import AppLink from 'components/AppLink';
import Image from 'components/Image';
import MovingArrow from 'components/MovingArrow';
import SubscribeToNewsletterButton from 'components/SubscribeToNewsletterButton';

import styles from './styles.styl';
import useAnalytics from 'hooks/useAnalytics';

const Articles = ({ className, theme, title, image, items }) => {
  const { elementClick } = useAnalytics();
  const handleItemClick = useCallback(() => {
    elementClick('80lv_site_trending-articles_click');
  }, [elementClick]);

  return (
    <div
      className={cn(styles.Articles, className, {
        [styles[`Articles_${theme}`]]: theme,
      })}
    >
      <div className={styles.Articles__header}>
        <Typography.Heading className={styles.Articles__headerTitle} level="5">
          {title}
        </Typography.Heading>

        {image ? (
          <div className={styles.Articles__headerIcon}>
            <Image image={image} isIcon />
          </div>
        ) : (
          <img
            src="/static/images/home/Articles/bird.svg"
            alt={title}
            className={styles.Articles__headerIcon}
          />
        )}
      </div>

      {isExist(items) && (
        <ul className={styles.Articles__list}>
          {items.map((article, i) => (
            <li key={article.id} className={styles.Articles__listItem}>
              <MovingArrow.Wrapper>
                <AppLink
                  href={`/articles/${article.slug}`}
                  className={styles.Articles__listLink}
                  onClick={handleItemClick}
                >
                  <div className={styles.Articles__listIconWrapper}>
                    <span className={styles.Articles__listNumber}>{i + 1}</span>
                    <MovingArrow className={styles.Articles__listIcon} />
                  </div>
                  {article.title}
                </AppLink>
              </MovingArrow.Wrapper>
            </li>
          ))}
        </ul>
      )}

      <SubscribeToNewsletterButton
        preset="tertiary"
        className={styles.Articles__subscribeButton}
        block="trending"
      />
    </div>
  );
};

Articles.defaultProps = {
  className: '',
};

Articles.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.array,
};

export default Articles;
