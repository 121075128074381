// Vendor
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

// Components
import SubscribeToNewsletterButton from 'components/SubscribeToNewsletterButton';
import AppLink from 'components/AppLink';
import Slider from 'components/Slider';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import BlockLabel from 'ui/BlockLabel';
import { Typography } from 'ui/Typography';
import FeaturedTopicCard from './FeaturedTopicCard';

import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const FeaturedTopic = ({ className, category, isTabletMedium, items, action, image }) => {
  const { elementClick } = useAnalytics();
  const handleGoToFeaturedTopic = useCallback(() => {
    elementClick('80lv_site_featured-topic_click', 'all');
  }, [elementClick]);

  const handleItemClick = useCallback(() => {
    elementClick('80lv_site_featured-topic_click', 'one');
  }, [elementClick]);

  const renderCards = useCallback(
    () =>
      items.map(topic => (
        <div key={topic.id} className={styles.FeaturedTopic__topicsItem}>
          <FeaturedTopicCard
            {...topic}
            className={styles.FeaturedTopic__topic}
            onClick={handleItemClick}
          />
        </div>
      )),
    [handleItemClick, items],
  );

  return (
    <div className={cn(styles.FeaturedTopic, className)}>
      <div className={styles.FeaturedTopic__inner}>
        <BlockLabel>
          <AppLink
            href={`/articles?category=${category.slug}`}
            as={`/articles/${category.slug}`}
            onClick={handleGoToFeaturedTopic}
          >
            <Avatar
              border
              cancelDefault
              borderColor="#fff"
              image={image || '/static/temp/ArticlePreview/preview4.jpg'}
              className={styles.FeaturedTopic__avatar}
            />
          </AppLink>
        </BlockLabel>

        <header className={styles.FeaturedTopic__header}>
          <Typography.Heading level="1" className={styles.FeaturedTopic__title}>
            <AppLink
              href={`/articles?category=${category.slug}`}
              as={`/articles/${category.slug}`}
              onClick={handleGoToFeaturedTopic}
            >
              {category.name}
            </AppLink>
          </Typography.Heading>
        </header>

        {isTabletMedium ? (
          <Slider
            className={styles.FeaturedTopic__slider}
            slideClass={styles.FeaturedTopic__slide}
            slidesPerView="auto"
          >
            {renderCards()}
          </Slider>
        ) : (
          <ul className={styles.FeaturedTopic__topics}>{renderCards()}</ul>
        )}

        <footer className={styles.FeaturedTopic__footer}>
          <SubscribeToNewsletterButton className={styles.FeaturedTopic__button} block="news" />
          <Button
            href={`/articles?category=${category.slug}`}
            as={`/articles/${category.slug}`}
            className={styles.FeaturedTopic__button}
            theme="none"
            size="medium"
            preset="secondary"
            withArrow
            onClick={handleGoToFeaturedTopic}
          >
            {action}
          </Button>
        </footer>
      </div>
    </div>
  );
};

FeaturedTopic.defaultProps = {
  className: '',
  category: {
    name: 'Animation',
    slug: 'animation',
  },
};

FeaturedTopic.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  items: PropTypes.array,
  category: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isTabletMedium: PropTypes.bool.isRequired,
};

const connector = connect(state => ({
  isTabletMedium: state.responsive.isTabletMedium || false,
}));

export default connector(FeaturedTopic);
