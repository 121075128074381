import React, { Children, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import Slider from 'components/Slider';
import { Typography } from 'ui/Typography';

import styles from './styles.styl';

const SliderWidget = ({
  className,
  title,
  theme,
  children,
  simulateTouch,
  loop,
  delay,
  demonstrationHandlers = [],
  ...otherProps
}) => {
  const [swiper, setSwiper] = useState();
  const [currentSlide, setCurrentSlide] = useState(1);

  const onMouseEnter = useCallback(() => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  }, [swiper]);

  const onMouseLeave = useCallback(() => {
    if (swiper) {
      swiper.autoplay.start();
    }
  }, [swiper]);

  const slideTo = useCallback(
    e => {
      if (swiper) {
        swiper.slideToLoop(Number(e.target.dataset.index - 1));
      }
    },
    [swiper],
  );

  const slideChange = useCallback(() => {
    if (swiper) {
      if (demonstrationHandlers[swiper.realIndex]) demonstrationHandlers[swiper.realIndex]();
      return setCurrentSlide(swiper.realIndex + 1);
    }
    return false;
  }, [demonstrationHandlers, swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', slideChange);
    }
  }, [slideChange, swiper]);

  return (
    <div
      className={cn(styles.SliderWidget, className, {
        [styles[`SliderWidget_${theme}`]]: theme,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.SliderWidget__header}>
        <Typography.Heading className={styles.SliderWidget__title} level="5">
          {title}
        </Typography.Heading>

        {Children.count(children) > 1 && (
          <div className={styles.SliderWidget__pagination}>
            {children
              .map(item => Number(item.key) + 1)
              .map(item => (
                <div
                  className={cn(styles.SliderWidget__bullet, {
                    [styles.SliderWidget__bullet_active]: currentSlide == item,
                  })}
                  key={item}
                  data-index={item}
                  onClick={slideTo}
                />
              ))}
          </div>
        )}
      </div>

      <Slider
        {...otherProps}
        slideClass={styles.SliderWidget__slide}
        slideActiveClass={styles.SliderWidget__slide_active}
        loop={loop}
        slidesPerView={1}
        simulateTouch={simulateTouch || false}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: delay || 10000,
        }}
        getSwiper={setSwiper}
      >
        {children}
      </Slider>
    </div>
  );
};

SliderWidget.defaultProps = {
  className: '',
  title: '',
  loop: true,
};

SliderWidget.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  simulateTouch: PropTypes.bool,
  loop: PropTypes.bool,
  delay: PropTypes.number,
  children: PropTypes.any.isRequired,
  demonstrationHandlers: PropTypes.arrayOf(PropTypes.func),
};

export default SliderWidget;
