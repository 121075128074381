import React from 'react';

import PropTypes from 'prop-types';
import ScrollbarSlider from 'components/ScrollbarSlider';

import CloudExperienceCard from './CloudExperienceCard';
import CloudExperienceActions from './CloudExperienceActions';

import styles from './styles.styl';
import Container from 'components/Container';

const CloudExperience = ({
  className,
  items,
  title = '3D Metasites by 80.lv Community',
  subtitle = 'Immerse yourself in a world of creative and innovative 3D Metasites crafted by the talented 80.lv community.',
}) => {
  return (
    <div className={styles.CloudExperience}>
      <Container>
        <ScrollbarSlider
          title={title}
          subtitle={subtitle}
          className={className}
          actions={<CloudExperienceActions />}
          navigation={false}
          overflow
        >
          {items.map(item => (
            <CloudExperienceCard
              size="small"
              key={item.id}
              authorName={item.author.name}
              title={item.name}
              views={item.views}
              logoSrc={item.logo || item.author.image}
              imageSrc={item.cover}
              link={`https://${process.env.PROD_HOST_URL}/cgs/${item.path}`}
            />
          ))}
        </ScrollbarSlider>
      </Container>
    </div>
  );
};

CloudExperience.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};

export default CloudExperience;
