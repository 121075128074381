// Vendor
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createURL } from 'utils/url';

// Components
import Heading from 'components/Heading';
import AppLink from 'components/AppLink';
import Image from 'components/Image';
import Tags from 'components/Tags';
import Avatar from 'components/Avatar';
import ArticleDescription from 'components/ArticleDescription';

import styles from './styles.styl';
import AddBookmark from 'components/AddBookmark';

/** Карточка автора */
const AuthorInfo = ({ avatar, name, date, pseudonym }) => {
  let TagName = 'div';
  let cardProps = {};

  // Если указан pseudonym карточка становится ссылкой
  if (pseudonym) {
    TagName = AppLink;
    cardProps = { href: `/author/${pseudonym}` };
  }

  return (
    <TagName
      className={cn(styles.ArticlePreview__infoItem, styles.ArticlePreview__infoItem_author)}
      {...cardProps}
    >
      <div className={styles.ArticlePreview__infoAvatar}>
        <Avatar className={styles.ArticlePreview__infoAvatarIcon} image={avatar} />
      </div>

      <p className={styles.ArticlePreview__infoValue}>{name}</p>
      <span className={styles.ArticlePreview__infoSeparator}></span>
      <p className={styles.ArticlePreview__infoLabel}>{date}</p>
    </TagName>
  );
};

AuthorInfo.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  date: PropTypes.string,
  pseudonym: PropTypes.string,
};

const ArticlePreview = ({
  id,
  className,
  slug,
  title,
  author,
  date,
  description,
  tags,
  image,
  preview,
  initRadius,
  inCategory,
  url,
  assetsPath,
  tagsPath,
  isBookmarkedByUser,
  onBookmarkAdded,
  onBookmarkRemoved,
}) => {
  const isOnlyDesktop = useSelector(state => state.responsive.isOnlyDesktop);

  const tagLink = useMemo(() => {
    return {
      url: tagsPath ? tagsPath.url : undefined,
      assetsPath: tagsPath ? tagsPath.assetsPath : '/articles/category',
    };
  }, [tagsPath]);

  const link = useMemo(() => {
    const browserLink = `${url}/${slug}`;

    return {
      href: assetsPath ? createURL(assetsPath, { slug }) : browserLink,
      as: assetsPath ? browserLink : null,
    };
  }, [assetsPath, slug, url]);

  return (
    <article
      className={cn(
        styles.ArticlePreview,
        {
          [styles['ArticlePreview_init-radius']]: initRadius,
          [styles['ArticlePreview_in-category']]: inCategory,
        },
        className,
      )}
    >
      <div className={styles.ArticlePreview__main}>
        <Heading className={styles.ArticlePreview__heading} level={2}>
          <AppLink href={link.href} as={link.as} className={styles.ArticlePreview__headingLink}>
            {title}
          </AppLink>
        </Heading>

        <ArticleDescription className={styles.ArticlePreview__text} parse theme="articlePreview">
          {description}
        </ArticleDescription>

        <div className={styles.ArticlePreview__info}>
          {author && <AuthorInfo date={date} {...author} />}
          <AddBookmark
            id={id}
            isBookmarkedByUser={isBookmarkedByUser}
            onBookmarkAdded={onBookmarkAdded}
            onBookmarkRemoved={onBookmarkRemoved}
          />
        </div>
      </div>

      <div className={styles.ArticlePreview__additional}>
        <div className={styles.ArticlePreview__imageWrapper}>
          <AppLink
            href={link.href}
            as={link.as}
            className={cn(
              styles.ArticlePreview__imageLink,
              styles.ArticlePreview__imageLink_preview,
            )}
          >
            <Image
              className={cn(
                styles.ArticlePreview__image,
                isOnlyDesktop && {
                  [styles.ArticlePreview__image_bg]: !image.blur,
                },
              )}
              image={isOnlyDesktop ? image : preview}
              alt={title}
              isBg={isOnlyDesktop ? !image.blur : true}
            />
          </AppLink>
        </div>

        <div className={styles.ArticlePreview__tagsBlock}>
          <Tags
            className={styles.ArticlePreview__tags}
            theme="dark"
            tags={tags}
            url={tagLink.url}
            assetsPath={tagLink.assetsPath}
          />
        </div>
      </div>
    </article>
  );
};

ArticlePreview.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.object,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tags: PropTypes.array.isRequired,
  author_pseudonym: PropTypes.string,
  url: PropTypes.string,
  assetsPath: PropTypes.string,
  tagsPath: PropTypes.object,
  initRadius: PropTypes.bool,
  inCategory: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isBookmarkedByUser: PropTypes.bool,
  onBookmarkAdded: PropTypes.func,
  onBookmarkRemoved: PropTypes.func,
};

ArticlePreview.defaultProps = {
  className: '',
  author_pseudonym: '',
  initRadius: true,
  inCategory: false,
  url: '/articles',
  assetsPath: null,
};

export default ArticlePreview;
