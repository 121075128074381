import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import Wysiwyg from 'components/Wysiwyg';

// Styles
import styles from './styles.styl';

const ArticleDescription = ({ className, parse, theme, children }) => {
  return (
    <div
      className={cn(styles.ArticleDescription, className, {
        [styles[`ArticleDescription_${theme}`]]: theme,
      })}
    >
      {parse ? <Wysiwyg text={children} /> : children}
    </div>
  );
};

ArticleDescription.defaultProps = {
  className: '',
  parse: false,
};

ArticleDescription.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  parse: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

export default ArticleDescription;
