import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { Link } from 'utils/routes';
import { ensureRouteName } from 'utils/helpers';

const NextLink = ({ route, params, targetBlank, replace, ...rest }) => {
  const {
    query: { ft },
  } = useRouter();
  const ftParam = ft ? { ft } : {};

  const aProps = useMemo(
    () => ({
      target: targetBlank ? '_blank' : undefined,
      rel: targetBlank ? 'nofollow noopener' : undefined,
    }),
    [targetBlank],
  );

  const routeName = ensureRouteName(route);
  const isSameOriginLink = Boolean(route);
  return isSameOriginLink ? (
    <Link route={routeName} replace={replace} params={{ ...params, ...ftParam }}>
      <a {...aProps} {...rest} />
    </Link>
  ) : (
    <a {...aProps} {...rest} />
  );
};

NextLink.propTypes = {
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ name: PropTypes.string })]),
  params: PropTypes.object,
  children: PropTypes.node,
  targetBlank: PropTypes.bool,
  replace: PropTypes.bool,
};

export default NextLink;
