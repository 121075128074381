import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const ConditionalLink = ({ children, href, isLink = !!href, element: Element, ...props }) => {
  return isLink ? (
    <Link href={href}>
      <a {...props}>{children}</a>
    </Link>
  ) : Element ? (
    <Element {...props}>{children}</Element>
  ) : (
    <>{children}</>
  );
};

ConditionalLink.propTypes = {
  isLink: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  element: PropTypes.string,
};

export default ConditionalLink;
