import React from 'react';

const PlayBoxIcon = props => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2998 11.9998C3.2998 7.19493 7.19493 3.2998 11.9998 3.2998H35.9998C40.8047 3.2998 44.6998 7.19493 44.6998 11.9998V35.9998C44.6998 40.8047 40.8047 44.6998 35.9998 44.6998H11.9998C7.19493 44.6998 3.2998 40.8047 3.2998 35.9998V11.9998ZM11.9998 6.2998C8.85178 6.2998 6.2998 8.85178 6.2998 11.9998V35.9998C6.2998 39.1478 8.85178 41.6998 11.9998 41.6998H35.9998C39.1478 41.6998 41.6998 39.1478 41.6998 35.9998V11.9998C41.6998 8.85178 39.1478 6.2998 35.9998 6.2998H11.9998ZM17.9366 13.1531C18.9839 12.6641 20.1217 12.9389 20.9705 13.4766L21.0149 13.5047L32.0264 21.613C32.8557 22.1104 33.753 22.9477 33.7547 24.2007C33.7564 25.4477 32.8613 26.321 32.0394 26.8611L21.0941 34.5163L21.0252 34.5544C20.1854 35.0192 19.0108 35.3839 17.9166 34.7995C16.807 34.2069 16.4641 33.008 16.4042 32.0015L16.4017 31.9592L16.3554 15.9729C16.336 14.8185 16.8112 13.6785 17.9366 13.1531ZM19.3555 16.0083L30.3762 24.1234L30.4481 24.165C30.4897 24.1891 30.5274 24.2122 30.5613 24.2341C30.5101 24.2735 30.4501 24.3161 30.3803 24.3616L30.3594 24.3752L19.5107 31.9628C19.4773 31.9801 19.4459 31.9955 19.4166 32.009C19.4106 31.965 19.4054 31.9158 19.4014 31.8612L19.3555 16.0083Z"
      fill="currentColor"
    />
  </svg>
);

export default PlayBoxIcon;
