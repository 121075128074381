import React from 'react';

const BookmarkFullIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.68339 2H18.315V4.10526H20.4202V22H18.315V20.1016H15.999V18H13.999V15.6842H9.89391L9.89391 17.7895H7.78865V19.8947H5.68339V22H3.57812V4.10526H5.68339V2Z"
      fill="currentColor"
    />
  </svg>
);

export default BookmarkFullIcon;
