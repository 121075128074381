import React from 'react';
import PropTypes from 'prop-types';

import { Routes80lv } from 'config/routes';

import ScrollbarSlider from 'components/ScrollbarSlider';

import FeaturedResearchActions from './FeaturedResearchActions';
import ResearchCard from './ResearchCard';

import styles from './styles.styl';
import Container from 'components/Container';

const FeaturedResearch = ({
  className,
  items,
  title = '80 Level Research',
  subtitle = 'Read the latest industry reports or request personalized market research tailored to your company’s needs.',
}) => {
  return (
    <div className={styles.FeaturedResearch}>
      <Container>
        <ScrollbarSlider
          title={title}
          subtitle={subtitle}
          className={className}
          actions={<FeaturedResearchActions />}
          navigation={false}
          overflow
        >
          {items.map((item, index) => (
            <ResearchCard
              key={item.id}
              index={index}
              link={Routes80lv.Article.pattern.replace(':slug', item.slug)}
              title={item.title}
              description={item.description}
              imageSrc={item.preview.src}
            />
          ))}
        </ScrollbarSlider>
      </Container>
    </div>
  );
};

FeaturedResearch.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};

export default FeaturedResearch;
