import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useImage } from 'hooks/useMedia';
import useLazyLoad from 'hooks/useLazyLoad';

import Avatar from 'ui/Avatar';
import BackgroundImage from 'ui/BackgroundImage';

import Heading from 'components/Heading';
import NextLink from 'components/NextLink';
import Preloader from 'components/Preloader';

import { RoutesRFP } from 'config/routes';

import styles from './styles.styl';

const portfolioType = PropTypes.shape({
  url_preview: PropTypes.string,
  domain: PropTypes.string.isRequired,
});

const profileType = PropTypes.shape({
  avatar: PropTypes.string,
  surname: PropTypes.string,
  position: PropTypes.string,
  name: PropTypes.string.isRequired,
});

const slidePropTypes = {
  profile: profileType.isRequired,
  portfolio: portfolioType.isRequired,
};

const fullName = (name, surname) => (surname ? `${name} ${surname}` : name);

const SmallPortfolioSlide = ({ portfolio, profile, className, ...restProps }) => {
  const { domain, url_preview } = portfolio;
  const { avatar, name, surname, position } = profile;
  const { load, isLoaded } = useImage(url_preview);
  const { ref } = useLazyLoad(load);

  return (
    <NextLink
      {...restProps}
      targetBlank
      params={{ domain }}
      route={RoutesRFP.Profile}
      className={cn(styles.SmallPortfolioSlide__wrapper, className)}
    >
      <div className={styles.SmallPortfolioSlide}>
        <Preloader isLoaded={isLoaded}>
          {({ className: preloaderClassName }) => (
            <BackgroundImage
              ref={ref}
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85.23%, rgba(0, 0, 0, 0.25) 100%), url(${url_preview})`,
              }}
              className={cn(styles.SmallPortfolioSlide__background_image, preloaderClassName)}
            />
          )}
        </Preloader>
        <Heading className={styles.SmallPortfolioSlide__profile_job} level={5} tagName="div">
          {position}
        </Heading>
      </div>
      <div className={styles.SmallPortfolioSlide__profile}>
        <Avatar className={styles.SmallPortfolioSlide__profile_avatar} size={20} image={avatar} />
        <Heading className={styles.SmallPortfolioSlide__profile_name} tagName="div">
          {fullName(name, surname)}
        </Heading>
      </div>
    </NextLink>
  );
};

SmallPortfolioSlide.propTypes = { ...slidePropTypes, last: PropTypes.bool };

export default SmallPortfolioSlide;
