import React from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';

import Arrow from './Arrow';

import styles from './styles.styl';

const cx = cn.bind(styles);

function Button({ className, theme, isOutlined, isUppercase, children, size, ...other }) {
  const buttonClassNames = cx(
    'Button',
    {
      [`Button--${size}`]: size !== 'md',
      [`Button--uppercase`]: isUppercase,
      [`Button--${theme}`]: theme && !isOutlined,
      [`Button--${theme}-outlined`]: theme && isOutlined,
    },
    className,
  );

  return (
    <button className={buttonClassNames} {...other}>
      {theme === 'back' && <Arrow />}
      {children}
    </button>
  );
}

Button.defaultProps = {
  size: 'md',
  className: '',
  isOutlined: false,
  isUppercase: true,
};

Button.propTypes = {
  size: PropTypes.string,
  theme: PropTypes.oneOf(['white', 'black', 'green', 'back']),
  children: PropTypes.node,
  isOutlined: PropTypes.bool,
  className: PropTypes.string,
  isUppercase: PropTypes.bool,
};

export default Button;
