import React from 'react';
import PropTypes from 'prop-types';

import { Routes80lv } from 'config/routes';

import ScrollbarSlider from 'components/ScrollbarSlider';
import JobOpportunityCard from 'components/JobOpportunityCard';

import FeaturedJobsActions from './FeaturedJobsActions';
import Container from 'components/Container';

import styles from './styles.styl';
import cn from 'classnames';

const FeaturedJobs = ({
  className,
  items,
  title = 'Job Opportunities',
  subtitle = 'Want to apply for your dream job?\nCheck out the latest Job Posts from top companies',
}) => {
  return (
    <div className={styles.FeaturedJobs}>
      <Container>
        <ScrollbarSlider
          title={title}
          subtitle={subtitle}
          className={cn(styles.FeaturedJobs__sliderVisibleOverflow, className)}
          actions={<FeaturedJobsActions />}
          navigation={false}
          overflow
        >
          {items.map((item, index) => (
            <JobOpportunityCard
              size="small"
              index={index}
              title={item.title}
              subtitle={item.company.title}
              link={Routes80lv.JobBoardCard.pattern.replace(':slug', item.slug)}
              bannerUrl={item.preview.src || item.company.preview.original}
              logoUrl={item.company.logo && item.company.logo.src}
              logoLink={Routes80lv.Partner.pattern.replace(':slug', item.company.partner_slug)}
              tags={item.categories}
              key={item.id}
            />
          ))}
        </ScrollbarSlider>
      </Container>
    </div>
  );
};

FeaturedJobs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};

export default FeaturedJobs;
