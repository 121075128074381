import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/Heading';
import Slider from 'components/Slider';
import Container from 'components/Container';
import Button from 'features/rfp/ui/Button';
import { useSelector } from 'react-redux';
import SmallPortfolioSlide from './SmallPortfolioSlide';
import WidePortfolioSlide from './WidePortfolioSlide';
import NextLink from 'components/NextLink';
import { RoutesRFP } from 'config/routes';

import styles from './styles.styl';

const screenStates = {
  wide: 'wide',
  small: 'small',
};

const isScreenWide = screenState => screenState === screenStates.wide;
const isScreenSmall = screenState => screenState === screenStates.small;

const renderSlides = (items, screenState) => {
  const Slide = isScreenSmall(screenState) ? SmallPortfolioSlide : WidePortfolioSlide;

  return items.map((slide, index) => <Slide key={`FeaturedPortfolio_slide_${index}`} {...slide} />);
};

function PromoRFPButton() {
  return (
    <NextLink className={styles.FeaturedPortfolios__link} route={RoutesRFP.Home} targetBlank>
      <Button className={styles.FeaturedPortfolios__button} isUppercase={false}>
        Explore more talents
      </Button>
    </NextLink>
  );
}

function FeaturedPortfolios({
  title = 'Hire a talent',
  subtitle = '80 Level Talent is a service for the quick request of proposal for game creatives',
  items,
}) {
  const hasItems = items && items.length > 0;
  const sliderParams = {
    containerClass: `swiper-container ${styles.FeaturedPortfolios__swiper_container}`,
    slidesPerView: 'auto',
    grabCursor: true,
    freeMode: true,
    shouldSwiperUpdate: true,
    mousewheel: {
      invert: true,
      forceToAxis: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  };

  const [screenState, setScreenState] = useState(screenStates.wide);
  const innerWidth = useSelector(state => state.responsive.innerWidth);
  useEffect(() => {
    if (innerWidth > 971) {
      setScreenState(screenStates.wide);
    } else {
      setScreenState(screenStates.small);
    }
  }, [innerWidth]);

  return (
    <div className={styles.FeaturedPortfolios__wrapper}>
      <Container className={styles.FeaturedPortfolios__content_wrapper}>
        <div className={styles.FeaturedPortfolios__header}>
          <div>
            <Heading className={styles.FeaturedPortfolios__title} level={2}>
              {title}
            </Heading>
            <Heading className={styles.FeaturedPortfolios__subtitle} level={3}>
              {subtitle}
            </Heading>
          </div>
          {isScreenWide(screenState) && <PromoRFPButton />}
        </div>
        {hasItems && <Slider {...sliderParams}>{renderSlides(items, screenState)}</Slider>}
        {isScreenSmall(screenState) && <PromoRFPButton />}
      </Container>
    </div>
  );
}

FeaturedPortfolios.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default FeaturedPortfolios;
