import React from 'react';

const HexagonIcon = props => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2498 3.50077C23.7139 3.23282 24.2857 3.23282 24.7498 3.50077L41.3774 13.1008C41.8415 13.3687 42.1274 13.8639 42.1274 14.3998V33.5998C42.1274 34.1357 41.8415 34.6309 41.3774 34.8988L24.7498 44.4988C24.2857 44.7668 23.7139 44.7668 23.2498 44.4988L6.62207 34.8988C6.15797 34.6309 5.87207 34.1357 5.87207 33.5998V14.3998C5.87207 13.8639 6.15797 13.3687 6.62207 13.1008L23.2498 3.50077ZM8.87207 15.2658V32.7338L23.9998 41.4677L39.1274 32.7338V15.2658L23.9998 6.53186L8.87207 15.2658ZM14.2976 19.6552C14.7086 18.9359 15.6249 18.686 16.3442 19.097L24 23.4718L31.6558 19.097C32.375 18.686 33.2913 18.9359 33.7023 19.6552C34.1133 20.3745 33.8634 21.2907 33.1442 21.7018L25.5 26.0699V32.1907C25.5 33.0191 24.8284 33.6907 24 33.6907C23.1715 33.6907 22.5 33.0191 22.5 32.1907V26.0699L14.8558 21.7018C14.1365 21.2907 13.8866 20.3745 14.2976 19.6552Z"
      fill="currentColor"
    />
  </svg>
);

export default HexagonIcon;
