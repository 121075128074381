import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import useOverflowText from 'hooks/useOverflowText';

import styles from './styles.styl';

// Hook for text overflow

const cx = cn.bind(styles);
const ROWS_LIMIT = 4;

function FeaturedArticleTitle({ title }) {
  const content = useMemo(() => {
    let strArr = title.split(' ');

    let str = '';

    for (let i = 0; i < strArr.length; i++) {
      str += `<span class="word">${strArr[i]}${i !== strArr.length - 1 ? ' ' : ''}</span>`;
    }

    return str;
  }, [title]);

  const titleRef = useOverflowText(ROWS_LIMIT);

  return (
    <h3
      ref={titleRef}
      className={cx('FeaturedArticleTitle')}
      dangerouslySetInnerHTML={{
        __html: `${content}`,
      }}
    />
  );
}

FeaturedArticleTitle.propTypes = {
  title: PropTypes.string,
};

export default FeaturedArticleTitle;
