import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { addBookmark, deleteBookmark } from 'state/bookmarks/actions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ToastContent } from 'ui/Toast';
import useAuth from 'features/auth/hooks/useAuth';
import useLogin from 'features/auth/hooks/useLogin';
import useAnalytics from 'hooks/useAnalytics';
import styles from './styles.styl';
import BookmarkEmptyIcon from 'ui/icons/BookmarkEmptyIcon';
import BookmarkFullIcon from 'ui/icons/BookmarkFullIcon';
import Tooltip from 'ui/Tooltip';

export const API_URL = process.env.API_URL;

const AddBookmark = ({ id, isBookmarkedByUser, onBookmarkAdded, onBookmarkRemoved }) => {
  const [isBookmarked, setIsBookmarked] = useState(isBookmarkedByUser);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { showFullscreenWidget } = useLogin();
  const { elementClick } = useAnalytics();

  const openAuthWidget = () => {
    showFullscreenWidget();
    elementClick('80lv_site_login-widget_open');
  };

  const addToBookmark = async id => {
    try {
      setIsLoading(true);
      await dispatch(addBookmark(id));
      setIsBookmarked(true);
      onBookmarkAdded && onBookmarkAdded();
    } catch (e) {
      toast(<ToastContent type="error" title="Error" body={e.message} />);
    } finally {
      setIsLoading(false);
    }
  };

  const removeBookmark = async id => {
    try {
      setIsLoading(true);
      await dispatch(deleteBookmark(id));
      setIsBookmarked(false);
      onBookmarkRemoved && onBookmarkRemoved();
    } catch (e) {
      toast(<ToastContent type="error" title="Server Error" body={e.message} />);
    } finally {
      setIsLoading(false);
    }
  };

  const onToggleBookmark = () => {
    if (!isAuthenticated) {
      openAuthWidget();
      return;
    }

    if (isLoading) {
      return;
    }

    if (!isBookmarked) {
      addToBookmark(id);
      return;
    }

    removeBookmark(id);
  };

  const bookmarkId = useMemo(() => {
    return `add-bookmark-${id}`;
  }, [id]);

  return (
    <>
      <div onClick={onToggleBookmark}>
        <div data-tip data-for={bookmarkId}>
          {isBookmarked ? (
            <BookmarkFullIcon className={styles.Images} />
          ) : (
            <BookmarkEmptyIcon className={styles.Images} />
          )}
        </div>
        <Tooltip id={bookmarkId} effect="solid">
          {isBookmarked ? 'Remove bookmark' : 'Add bookmark'}
        </Tooltip>
      </div>
    </>
  );
};

AddBookmark.defaultProps = {};

AddBookmark.propTypes = {
  id: PropTypes.number,
  isBookmarkedByUser: PropTypes.bool,
  onBookmarkAdded: PropTypes.func,
  onBookmarkRemoved: PropTypes.func,
};

export default AddBookmark;
