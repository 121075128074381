import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Link from 'next/link';

import Image from 'ui/Image';
import EyeOpenIcon from 'ui/icons/EyeOpenIcon';
import HexagonIcon from 'ui/icons/HexagonIcon';
import PlayBoxIcon from 'ui/icons/PlayBoxIcon';

import styles from './styles.styl';

const CloudExperienceCard = ({ link, imageSrc, views, logoSrc, authorName, title, size }) => {
  return (
    <Link href={link}>
      <a
        className={cn(styles.CloudExperienceCard, {
          [styles[`CloudExperienceCard_size-${size}`]]: size,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.CloudExperienceCard__content}>
          <Image className={styles.CloudExperienceCard__image} src={imageSrc} />
          <div className={styles.CloudExperienceCard__views}>
            <EyeOpenIcon className={styles.CloudExperienceCard__viewsIcon} />
            {typeof views === 'number' ? views.toLocaleString() : views}
          </div>
          <div className={styles.CloudExperienceCard__icons}>
            <HexagonIcon
              className={cn(styles.CloudExperienceCard__icon, styles.CloudExperienceCard__hexagon)}
            />
            <PlayBoxIcon
              className={cn(styles.CloudExperienceCard__icon, styles.CloudExperienceCard__playBox)}
            />
          </div>
        </div>
        <div className={styles.CloudExperienceCard__author}>
          <Image className={styles.CloudExperienceCard__authorLogo} src={logoSrc} />
          <div className={styles.CloudExperienceCard__authorInfo}>
            <span className={styles.CloudExperienceCard__authorName}>{authorName}</span>
            <span className={styles.CloudExperienceCard__authorPosition}>{title}</span>
          </div>
        </div>
      </a>
    </Link>
  );
};

CloudExperienceCard.propTypes = {
  link: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
  logoSrc: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small']),
};

export default CloudExperienceCard;
