import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'next/link';

import { Routes80lv } from 'config/routes';

import Image from 'ui/Image';
import Tags from 'components/Tags';
import { ConditionalLink } from 'components/ConditionalLink';

import styles from './styles.styl';

const JobOpportunityCard = ({
  className,
  index,
  link,
  title,
  subtitle,
  logoUrl,
  bannerUrl,
  logoLink,
  tags,
  size,
}) => {
  const hasTags = Boolean(tags && tags.length);

  return (
    <div
      className={cn(
        styles.JobOpportunityCard,
        styles[`CompanyCard_index${index % 5}`],
        { [styles[`JobOpportunityCard_size-${size}`]]: size },
        className,
      )}
    >
      <Link href={link}>
        <a className={styles.JobOpportunityCard__banner}>
          <Image src={bannerUrl} className={styles.JobOpportunityCard__bannerImage} />
        </a>
      </Link>
      <div className={styles.JobOpportunityCard__content}>
        <div
          className={cn(styles.JobOpportunityCard__contentGeneral, {
            [styles.JobOpportunityCard__contentGeneral_short]: hasTags,
          })}
        >
          <ConditionalLink href={logoLink} className={styles.JobOpportunityCard__logo}>
            <Image src={logoUrl} className={styles.JobOpportunityCard__logoImage} />
          </ConditionalLink>
          <div className={styles.JobOpportunityCard__info}>
            <Link href={link}>
              <a className={styles.JobOpportunityCard__title}>{title}</a>
            </Link>
            <ConditionalLink
              className={styles.JobOpportunityCard__jobs}
              href={logoLink}
              element="div"
            >
              <p className={styles.JobOpportunityCard__subtitle}>{subtitle}</p>
              <p className={styles.JobOpportunityCard__status}>Hiring</p>
            </ConditionalLink>
          </div>
        </div>
        {hasTags && (
          <Tags
            className={styles.JobOpportunityCard__tags}
            theme="dark"
            tags={tags}
            url={Routes80lv.JobBoard.pattern}
            queryName="skills"
          />
        )}
      </div>
    </div>
  );
};

JobOpportunityCard.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  bannerUrl: PropTypes.string.isRequired,
  logoLink: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  size: PropTypes.oneOf(['small']),
};

export default JobOpportunityCard;
