// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';
const cx = cn.bind(styles);

const ArticlePreviewSceleton = ({ inCategory, initRadius, className }) => (
  <article
    className={cx(
      'ArticlePreviewSceleton',
      {
        'ArticlePreviewSceleton_in-category': inCategory,
        [styles['ArticlePreviewSceleton_init-radius']]: initRadius,
      },
      className,
    )}
  >
    <div className={cx('ArticlePreviewSceleton__main')}>
      <div className={cx('ArticlePreviewSceleton__heading')}>
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_header',
            'ArticlePreviewSceleton__item_headerFirst',
          )}
        />
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_header',
            'ArticlePreviewSceleton__item_headerSecond',
          )}
        />
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_header',
            'ArticlePreviewSceleton__item_headerThird',
          )}
        />
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_header',
            'ArticlePreviewSceleton__item_headerFourth',
          )}
        />
      </div>

      <div className={cx('ArticlePreviewSceleton__text')}>
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_text',
            'ArticlePreviewSceleton__item_textFirst',
          )}
        />
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_text',
            'ArticlePreviewSceleton__item_textSecond',
          )}
        />
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_text',
            'ArticlePreviewSceleton__item_textThird',
          )}
        />
      </div>

      <div className={cx('ArticlePreviewSceleton__groups')}>
        <div
          className={cx('ArticlePreviewSceleton__group', 'ArticlePreviewSceleton__group_author')}
        >
          <div
            className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_avatar')}
          />

          <div className={cx('ArticlePreviewSceleton__items')}>
            <div
              className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_author')}
            />
            <div
              className={cx(
                'ArticlePreviewSceleton__item',
                'ArticlePreviewSceleton__item_authorName',
              )}
            />
          </div>
        </div>

        <div className={cx('ArticlePreviewSceleton__group')}>
          <div className={cx('ArticlePreviewSceleton__items')}>
            <div
              className={cx(
                'ArticlePreviewSceleton__item',
                'ArticlePreviewSceleton__item_dateTitle',
              )}
            />
            <div
              className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_date')}
            />
          </div>
        </div>
      </div>
    </div>

    <div className={cx('ArticlePreviewSceleton__additional')}>
      <div className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_image')} />

      <div
        className={cx('ArticlePreviewSceleton__heading', 'ArticlePreviewSceleton__heading_phone')}
      >
        <div
          className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_headerFirst')}
        />
        <div
          className={cx(
            'ArticlePreviewSceleton__item',
            'ArticlePreviewSceleton__item_headerSecond',
          )}
        />
        <div
          className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_headerThird')}
        />
      </div>

      <div className={cx('ArticlePreviewSceleton__groups', 'ArticlePreviewSceleton__groups_phone')}>
        <div
          className={cx('ArticlePreviewSceleton__group', 'ArticlePreviewSceleton__group_author')}
        >
          <div
            className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_avatar')}
          />

          <div className={cx('ArticlePreviewSceleton__items')}>
            <div
              className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_author')}
            />
            <div
              className={cx(
                'ArticlePreviewSceleton__item',
                'ArticlePreviewSceleton__item_authorName',
              )}
            />
          </div>
        </div>

        <div className={cx('ArticlePreviewSceleton__group')}>
          <div className={cx('ArticlePreviewSceleton__items')}>
            <div
              className={cx('ArticlePreviewSceleton__item ArticlePreviewSceleton__item_dateTitle')}
            />
            <div
              className={cx('ArticlePreviewSceleton__item', 'ArticlePreviewSceleton__item_date')}
            />
          </div>
        </div>
      </div>
    </div>
  </article>
);

ArticlePreviewSceleton.propTypes = {
  inCategory: PropTypes.bool,
  initRadius: PropTypes.bool,
  className: PropTypes.string,
};

ArticlePreviewSceleton.defaultProps = {
  inCategory: false,
  initRadius: true,
  className: '',
};

export default ArticlePreviewSceleton;
