import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.styl';

function PriceLabel({ className, value }) {
  return <span className={cn(styles.PriceLabel, className)}>{value}</span>;
}

PriceLabel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

export default PriceLabel;
