// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import AppLink from 'components/AppLink';
import Heading from 'components/Heading';
import Image from 'components/Image';
import Tags from 'components/Tags';

import styles from './styles.styl';

const FeaturedTopicCard = ({ className, title, slug, image, tags, onClick }) => (
  <div className={cn(styles.FeaturedTopicCard, className)}>
    <AppLink
      href={`/articles/${slug}`}
      className={styles.FeaturedTopicCard__pictureLink}
      onClick={onClick}
    >
      <div className={styles.FeaturedTopicCard__pictureImage}>
        <Image className={styles.FeaturedTopicCard__picture} image={image} alt={title} />
      </div>
    </AppLink>

    <div className={styles.FeaturedTopicCard__content}>
      <Heading className={styles.FeaturedTopicCard__title} level={3}>
        <AppLink href={`/articles/${slug}`}>{title}</AppLink>
      </Heading>

      <Tags
        className={styles.FeaturedTopicCard__tags}
        tags={tags}
        assetsPath="/articles/category"
      />
    </div>
  </div>
);

FeaturedTopicCard.defaultProps = {
  className: '',
};

FeaturedTopicCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tags: PropTypes.array,
  onClick: PropTypes.func,
};

export default FeaturedTopicCard;
