import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import useOverflowText from 'hooks/useOverflowText';

import styles from './styles.styl';

// Hook for text overflow

const cx = cn.bind(styles);
const ROWS_LIMIT = 4;

function FeaturedArticleDescription({ text }) {
  const content = useMemo(() => {
    let strArr = text.split(' ');

    let str = '';

    for (let i = 0; i < strArr.length; i++) {
      str += `<span class="word">${strArr[i]}${i !== strArr.length - 1 ? ' ' : ''}</span>`;
    }

    return str;
  }, [text]);

  const textRef = useOverflowText(ROWS_LIMIT);

  return (
    <div
      ref={textRef}
      className={cx('FeaturedArticleDescription')}
      dangerouslySetInnerHTML={{
        __html: `${content}`,
      }}
    />
  );
}

FeaturedArticleDescription.propTypes = {
  text: PropTypes.string,
};

export default FeaturedArticleDescription;
