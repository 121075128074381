import { useMemo } from 'react';

const ARTICLE_SECTION_COUNT = 3;

/** Возвращает статьи разбитые по секциям */
export default function useHomeArticlesSection(articles, content) {
  return useMemo(() => {
    const section = [];
    const sectionsCount = content.length < 2 ? 1 : Math.ceil(articles.length / content.length);

    // Разбиение статей на секции
    for (let i = 0; i < sectionsCount; i++) {
      if (i === sectionsCount - 1) {
        section.push(articles.slice(i * ARTICLE_SECTION_COUNT, articles.length));
      } else {
        section.push(articles.slice(i * ARTICLE_SECTION_COUNT, (i + 1) * ARTICLE_SECTION_COUNT));
      }
    }

    return section;
  }, [articles, content]);
}
