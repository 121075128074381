import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/Button';
import MailIcon from 'ui/icons/MailIcon';
import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const SubscribeToNewsletterButton = ({ className, buttonPreset, block, ...restProps }) => {
  const { elementClick } = useAnalytics();
  const handleClick = () => {
    const subscribeForm = document.getElementById('subscribe-form');
    if (subscribeForm) {
      subscribeForm.scrollIntoView({ block: 'center', behavior: 'smooth' });
      elementClick('80lv_site_main-page_scroll-to-subscription', block);
    }
  };
  return (
    <Button
      className={cn(styles.SubscribeToNewsletterButton, className)}
      theme="none"
      preset={buttonPreset}
      size="medium"
      onClick={handleClick}
      {...restProps}
    >
      Subscribe to newsletter
      <MailIcon className={styles.SubscribeToNewsletterButton__icon} />
    </Button>
  );
};

SubscribeToNewsletterButton.defaultProps = {
  buttonPreset: 'secondary',
};

SubscribeToNewsletterButton.propTypes = {
  className: PropTypes.string,
  buttonPreset: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  block: PropTypes.string,
};

export default SubscribeToNewsletterButton;
