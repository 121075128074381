import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useAnalytics from 'hooks/useAnalytics';

// Components
import SliderWidget from 'components/SliderWidget';
import AppLink from 'components/AppLink';
import Image from 'components/Image';
import BaseImage from 'ui/Image';
import FeaturedArticleTitle from './FeaturedArticleTitle';
import FeaturedArticleDescription from './FeaturedArticleDescription';
import PriceLabel from 'components/PriceLabel';
import Tags from 'components/Tags';
import isElementVisible from '../../../utils/isElementVisible';

import styles from './styles.styl';

const FeaturedArticle = React.forwardRef(({ className, title, theme, items }, forwardRef) => {
  const { customEvent, elementClick } = useAnalytics();

  const demonstrationHandlers = useMemo(
    () =>
      theme === 'header-workshop'
        ? items.map(({ id }) => () =>
            isElementVisible(forwardRef.current) &&
            customEvent('80lv_workshops_banner_impression', { workshop_id: id }),
          )
        : [],
    [customEvent, forwardRef, items, theme],
  );

  const onClickCurry = useCallback(
    id => {
      if (theme === 'header-workshop')
        return () => elementClick('80lv_workshops_banner_click', undefined, id);

      return () => {};
    },
    [elementClick, theme],
  );

  return (
    <div
      ref={forwardRef}
      className={cn(styles.FeaturedArticle, className, {
        [styles[`FeaturedArticle_${theme}`]]: theme,
      })}
    >
      <SliderWidget
        loop={false}
        delay={3000}
        simulateTouch
        theme="main"
        title={title}
        className={styles.FeaturedArticle__slider}
        demonstrationHandlers={demonstrationHandlers}
      >
        {items.map((item, key) => {
          const href = item.slug ? `/articles/${item.slug}` : item.link;
          const isTargetBlank = Boolean(item.link);
          const onClick = onClickCurry(item.id);
          const onLoad = key === 0 ? demonstrationHandlers[0] : () => {};

          return (
            <div key={key} className={styles.FeaturedArticle__slide}>
              <div className={styles.FeaturedArticle__slideImage}>
                <Image
                  onLoad={onLoad}
                  alt={item.title}
                  image={item.image}
                  className={styles.FeaturedArticle__slidePicture}
                />
                <div className={styles.FeaturedArticle__slideShadow} />
              </div>
              <AppLink
                href={href}
                onClick={onClick}
                targetBlank={isTargetBlank}
                className={styles.FeaturedArticle__slideLink}
              />
              <div className={styles.FeaturedArticle__slideContent}>
                <div className={styles.FeaturedArticle__slideInfo}>
                  <div className={styles.FeaturedArticle__slideTitle}>
                    <FeaturedArticleTitle title={item.title} />
                    {item.price && (
                      <PriceLabel
                        className={cn(
                          styles.FeaturedArticle__price,
                          styles.FeaturedArticle__price_desktop,
                        )}
                        value={item.price}
                      />
                    )}
                  </div>
                  {item.description && <FeaturedArticleDescription text={item.description} />}
                </div>
                {(item.price || item.school) && (
                  <div
                    className={cn(
                      styles.FeaturedArticle__slideFooter,
                      styles.FeaturedArticle__slideFooter_inWorkshops,
                    )}
                  >
                    {item.price && (
                      <PriceLabel
                        className={cn(
                          styles.FeaturedArticle__price,
                          styles.FeaturedArticle__price_phone,
                        )}
                        value={item.price}
                      />
                    )}
                    {item.school && (
                      <div className={styles.FeaturedArticle__slideSchool}>
                        <BaseImage src={item.school.image.src} src2x={item.school.image.src2x} />
                      </div>
                    )}
                  </div>
                )}
                {item.tags && Boolean(item.tags.length) && (
                  <Tags
                    className={styles.FeaturedArticle__slideFooter}
                    align="right"
                    theme="dark"
                    tags={item.tags}
                    assetsPath="/articles/category"
                  />
                )}
              </div>
            </div>
          );
        })}
      </SliderWidget>
    </div>
  );
});

FeaturedArticle.displayName = 'FeaturedArticle';

FeaturedArticle.DefaultProps = {
  className: '',
};

FeaturedArticle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  theme: PropTypes.string,
};

export default FeaturedArticle;
