import React, { Fragment } from 'react';

import Button from 'components/Button';
import styles from './styles.styl';
import { Routes80lv } from 'config/routes';
import { createURL } from 'utils/url';
import Link from 'next/link';

const FeaturedResearchActions = () => {
  return (
    <Fragment>
      <Link
        href={createURL(`/${Routes80lv.Category.page}`, {
          slug: 'research',
        })}
        as={Routes80lv.Category.pattern.replace(':slug', 'research')}
      >
        <a>
          <Button className={styles.FeaturedResearchActions__button} theme="dark-transparent">
            See all reports
          </Button>
        </a>
      </Link>
      <Button
        className={styles.FeaturedResearchActions__button}
        isLink
        href="/research"
        theme="dark"
      >
        Request research
      </Button>
    </Fragment>
  );
};

export default FeaturedResearchActions;
