import { useRef, useEffect } from 'react';

export default function(rowsLimit) {
  const ref = useRef();

  useEffect(() => {
    function hideText() {
      let rowCount = 0;
      let isSetPoints = false;

      if (ref.current) {
        for (let i = 0; i < ref.current.children.length; i++) {
          let textBlock = ref.current.children[i];

          if (ref.current.children[i].classList.contains('last')) {
            ref.current.children[i].classList.remove('last');
            ref.current.children[i].style.width = 'auto';
          }

          if (textBlock.offsetLeft === 0) {
            rowCount++;

            if (rowCount === rowsLimit && !isSetPoints) {
              ref.current.children[i - 1].classList.add('last');
              ref.current.children[i - 1].style.width = `${ref.current.children[i - 1].clientWidth -
                5}px`;
              isSetPoints = true;
            }
          }
        }
      }
    }

    hideText();

    window.addEventListener('resize', hideText);

    return () => {
      window.removeEventListener('resize', hideText);
    };
  });

  return ref;
}
