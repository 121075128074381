import React from 'react';
import { toast } from 'react-toastify';

import ToastContent from './ToastContent';

export const notify = props => {
  return toast(<ToastContent {...props} />);
};

export const notifyError = ({ error, message }) =>
  notify({ type: 'error', title: 'Error', body: message || error.message });
