import React from 'react';

import Container from 'components/Container';
import FeaturedPortfolios from 'components/FeaturedPortfolios';
import FeaturedJobs from 'components/FeaturedJobs';
import FeaturedResearch from 'components/FeaturedResearch';
import CloudExperience from 'components/CloudExperience';

export const getWrappedContent = (condition, content) =>
  condition ? <Container>{content}</Container> : content;

export const SLIDER_COMPONENTS = {
  'featured-portfolios': FeaturedPortfolios,
  'featured-jobs': FeaturedJobs,
  'featured-research': FeaturedResearch,
  'cloud-experience': CloudExperience,
};
