import React from 'react';
import Button from 'components/Button';

import styles from './styles.styl';

const CloudExperienceActions = () => {
  return (
    <Button className={styles.CloudExperienceActions__button} theme="dark-transparent">
      Start exploring now
    </Button>
  );
};

export default CloudExperienceActions;
