/** Позиция относительно документа
 *
 * @typedef OffsetObject
 * @type {object}
 * @property {number} width
 * @property {number} height
 * @property {number} top
 * @property {number} bottom
 * @property {number} left
 *
 * @param {object} element - DOM элемент
 * @returns {OffsetObject} - { width, height, top, bottom, left}
 */
export const getOffset = element => {
  let elementRect = element.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const offset = { top: elementRect.top + scrollTop, left: elementRect.left + scrollLeft };

  return Object.assign(offset, {
    width: elementRect.width,
    height: elementRect.height,
    bottom: offset.top + elementRect.height,
  });
};

// Позиция скролла с учетом fixed у body
export const getPageScrollTop = () => {
  if (!document) return 0;

  if (
    document.body.style.position === 'fixed' ||
    document.body.classList.contains('scroll-locked')
  ) {
    return Math.abs(Number.parseFloat(document.body.style.top)) || 0;
  } else {
    return window.pageYOffset || document.documentElement.scrollTop;
  }
};
