import React from 'react';

const EyeOpenIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      opacity="1.000000"
      stroke="none"
      d="
M1.000000,246.468536 
	C5.902046,239.882690 8.764751,232.559250 12.796542,225.917465 
	C20.054951,213.960297 27.839346,202.376816 36.521225,191.454559 
	C46.441158,178.974792 56.994427,167.004044 68.662735,156.089081 
	C87.157509,138.788422 107.071465,123.420639 129.127609,110.819160 
	C151.820023,97.854156 175.844452,88.484467 201.180176,82.216629 
	C225.831070,76.118217 250.985611,75.131233 275.974670,77.008224 
	C308.100067,79.421227 338.786957,88.300049 367.809052,102.754189 
	C393.091888,115.346031 416.066284,131.041473 437.167023,149.849747 
	C467.346649,176.750549 491.056030,208.386398 509.297546,244.306519 
	C513.600952,252.780579 513.601868,261.145935 509.321533,269.649017 
	C498.816223,290.518311 486.065826,309.992462 471.221619,327.932587 
	C446.062347,358.338989 416.675079,383.936737 382.186981,403.497894 
	C357.147919,417.699738 330.652740,427.686920 302.433990,433.545563 
	C280.390137,438.122192 258.080994,438.674561 235.985458,437.019470 
	C204.026169,434.625488 173.490753,425.802673 144.611420,411.388458 
	C122.200027,400.202515 101.586235,386.579224 82.276924,370.620880 
	C53.343708,346.708801 30.446484,317.974213 11.412322,285.864349 
	C7.844197,279.845093 5.069240,273.439728 1.257397,267.218292 
	C1.000000,260.312347 1.000000,253.624710 1.000000,246.468536 
M102.919586,182.469330 
	C100.626251,185.134476 97.206703,186.420502 94.960037,189.210663 
	C88.204430,197.600555 80.417931,205.062714 73.633232,213.480530 
	C63.282860,226.322266 54.145851,239.906540 45.659454,253.987839 
	C44.423431,256.038757 44.461838,257.911926 45.698261,260.049988 
	C58.813931,282.729980 74.649925,303.411499 92.816803,322.180450 
	C106.205330,336.012695 121.013054,348.293518 137.214874,358.908173 
	C164.967453,377.090393 194.842896,389.293793 227.863144,393.861450 
	C248.455719,396.709961 268.993896,396.562256 289.363403,393.297668 
	C310.096802,389.974823 330.013977,383.404114 348.815430,373.901245 
	C376.951019,359.680573 401.636353,340.945190 423.147369,317.793488 
	C439.407104,300.293518 453.581543,281.385406 465.775421,260.899658 
	C467.534454,257.944427 467.548035,255.801056 465.712250,252.763626 
	C455.809570,236.378845 444.638641,220.988907 432.356384,206.273102 
	C421.230225,192.942444 408.879150,180.914978 395.509308,170.073730 
	C378.988647,156.677521 361.094879,145.272781 341.484436,136.584015 
	C319.115997,126.673256 295.853394,120.205811 271.556793,118.602432 
	C251.692383,117.291550 231.799606,118.252815 212.176498,122.877304 
	C191.203430,127.819923 171.315308,135.222931 152.530762,145.803009 
	C134.666779,155.864578 118.154465,167.676285 102.919586,182.469330 
z"
    />
    <path
      fill="currentColor"
      opacity="1.000000"
      stroke="none"
      d="
M279.666992,158.893463 
	C298.278992,162.965652 314.008362,171.972504 327.025299,184.832809 
	C342.478912,200.100464 352.434448,218.743896 355.396423,240.641479 
	C358.337830,262.386566 356.400940,283.578979 345.962555,303.127625 
	C330.145660,332.748810 305.770050,351.432983 272.211945,356.358521 
	C241.288620,360.897369 213.170563,354.003174 189.346527,332.790558 
	C167.474884,313.316345 156.329971,289.048492 155.547424,259.669556 
	C154.896423,235.227615 161.536682,213.366943 176.762009,194.320175 
	C192.838470,174.208633 213.535095,161.514221 239.182571,157.671921 
	C252.553329,155.668808 265.976013,155.850235 279.666992,158.893463 
M233.807220,202.422867 
	C203.563019,214.801331 188.261719,250.283585 201.997925,280.197418 
	C215.335434,309.242950 246.134995,324.987762 278.990997,311.014313 
	C307.685638,298.810699 324.282349,266.978851 309.972717,233.767258 
	C297.681610,205.240509 266.543304,190.098129 233.807220,202.422867 
z"
    />
  </svg>
);

export default EyeOpenIcon;
