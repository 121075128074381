import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Avatar from 'ui/Avatar';
import BackgroundImage from 'ui/BackgroundImage';

import { useImage } from 'hooks/useMedia';
import useLazyLoad from 'hooks/useLazyLoad';

import Heading from 'components/Heading';
import Preloader from 'components/Preloader';
import NextLink from 'components/NextLink';

import { RoutesRFP } from 'config/routes';

import styles from './styles.styl';

const portfolioType = PropTypes.shape({
  url_preview: PropTypes.string,
  domain: PropTypes.string.isRequired,
});

const profileType = PropTypes.shape({
  avatar: PropTypes.string,
  surname: PropTypes.string,
  position: PropTypes.string,
  name: PropTypes.string.isRequired,
});

const slidePropTypes = {
  profile: profileType.isRequired,
  portfolio: portfolioType.isRequired,
};

const fullName = (name, surname) => (surname ? `${name} ${surname}` : name);

const WidePortfolioSlide = ({ portfolio, profile, ...restProps }) => {
  const [hover, setHover] = useState(false);

  const { domain, url_preview } = portfolio;
  const { avatar, name, surname, position } = profile;
  const { load, isLoaded } = useImage(url_preview);
  const { ref } = useLazyLoad(load);

  const backgroundImage = hover
    ? `url(${url_preview})`
    : `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${url_preview})`;
  const profileOpacity = hover ? 0 : 1;

  return (
    <NextLink
      {...restProps}
      targetBlank
      params={{ domain }}
      route={RoutesRFP.Profile}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cn(styles.WidePortfolioSlide, restProps.className)}
    >
      <Preloader isLoaded={isLoaded}>
        {({ className: preloaderClassName }) => (
          <BackgroundImage
            ref={ref}
            style={{
              backgroundImage,
            }}
            className={cn(styles.WidePortfolioSlide__background_image, preloaderClassName)}
          />
        )}
      </Preloader>

      <div
        className={styles.WidePortfolioSlide__profile_wrapper}
        style={{ opacity: profileOpacity }}
      >
        <div className={styles.WidePortfolioSlide__profile}>
          <Avatar size={44} image={avatar} className={styles.WidePortfolioSlide__profile_avatar} />
          <Heading className={styles.WidePortfolioSlide__profile_name} tagName="div">
            {fullName(name, surname)}
          </Heading>
          <Heading className={styles.WidePortfolioSlide__profile_job} tagName="div">
            {position}
          </Heading>
        </div>
      </div>
    </NextLink>
  );
};

WidePortfolioSlide.propTypes = slidePropTypes;

export default WidePortfolioSlide;
