import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'next/link';

import Image from 'ui/Image';

import styles from './styles.styl';
import EyeOpenIcon from 'ui/icons/EyeOpenIcon';

const ResearchCard = ({ className, index, link, title, description, imageSrc }) => {
  return (
    <Link href={link}>
      <a className={cn(styles.ResearchCard, styles[`ResearchCard_index${index % 4}`], className)}>
        <Image src={imageSrc} className={styles.ResearchCard__banner} />
        <div className={styles.ResearchCard__content}>
          <h4 className={styles.ResearchCard__title}>{title}</h4>
          <div className={styles.ResearchCard__descriptionWrapper}>
            <p className={styles.ResearchCard__description}>{description}</p>
          </div>
          <EyeOpenIcon className={styles.ResearchCard__icon} />
        </div>
      </a>
    </Link>
  );
};

ResearchCard.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default ResearchCard;
