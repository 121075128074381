import React from 'react';
import PropTypes from 'prop-types';

// Component
import { Typography } from 'ui/Typography';
import Image from 'components/Image';

// Styles
import cn from 'classnames/bind';
import styles from './styles.styl';

const cx = cn.bind(styles);

const ArticlesHeader = ({ title, image, className }) => {
  return (
    <div className={cx('ArticlesHeader', className)}>
      {Boolean(title) && (
        <Typography.Heading className={cx('ArticlesHeader__title')} level="5">
          {title}
        </Typography.Heading>
      )}
      {Boolean(image) && (
        <Image
          isIcon
          className={cx('ArticlesHeader__image')}
          image={image}
          alt={title || 'Articles icon'}
        />
      )}
    </div>
  );
};

ArticlesHeader.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

ArticlesHeader.defaultProps = {
  title: '',
  image: '',
  className: '',
};

export default ArticlesHeader;
