import React from 'react';

const MailIcon = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4.10938H4V6.10938H2V18.1094H4V20.1094H20V18.1094H22V6.10938H20V4.10938ZM18 8.10938H20V18.1094H4V8.10938H6V10.1094H8V12.1094H10V14.1094H12H14V12.1094H16V10.1094H18V8.10938ZM18 8.10938H16V10.1094H14V12.1094H12H10V10.1094H8V8.10938H6V6.10938H18V8.10938Z"
      fill="currentColor"
    />
  </svg>
);

export default MailIcon;
