import types from './types';
import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Loading bookmarks
const loadBookmarksStart = () => ({
  type: types.LOAD_BOOKMARKS_REQUEST,
});

const loadBookmarksDone = data => ({
  type: types.LOAD_BOOKMARKS_SUCCESS,
  data,
});

// Updating bookmarks
const updateBookmarksStart = () => ({
  type: types.UPDATE_BOOKMARKS_REQUEST,
});

const updateBookmarksDone = data => ({
  type: types.UPDATE_BOOKMARKS_SUCCESS,
  data,
});

const updateBookmarksFail = error => ({
  type: types.UPDATE_BOOKMARKS_FAILURE,
  error,
});

export const loadBookmarks = ({ username, slug, sort = 'published_at' } = {}) => (
  dispatch,
  getState,
) => {
  dispatch(loadBookmarksStart());

  const {
    profile,
    auth: { token },
  } = getState();

  const consumerId = profile.id;
  const headers = {
    ...(consumerId ? { ['X-Consumer']: consumerId } : undefined),
    ...(token ? { token } : undefined),
  };

  const url = `/bookmark/${username && slug ? `view/${username}/${slug}` : 'me'}`;

  return get({ url, headers, data: { sort } }).then(data => {
    return dispatch(
      loadBookmarksDone({
        ...data,
        collection: {
          ...data.collection,
          slug: `/bookmarks/${data.collection.slug}`,
        },
      }),
    );
  });
};

export const loadBookmarksIfNotLoading = query => (dispatch, getState) => {
  const {
    bookmarks: { isLoading },
  } = getState();

  if (!isLoading) {
    return dispatch(loadBookmarks(query));
  } else {
    return false;
  }
};

export const updateBookmarks = createAsyncThunk(
  'bookmark/update',
  async (data, { extra: httpService, dispatch }) => {
    dispatch(updateBookmarksStart());

    const response = await httpService
      .post({
        url: '/bookmark/update',
        data,
      })
      .then(() => dispatch(updateBookmarksDone(data)))
      .catch(error => dispatch(updateBookmarksFail(error)));
    return response;
  },
);

export const addBookmark = createAsyncThunk('bookmark/add', async (id, { extra: httpService }) => {
  const response = await httpService.post({
    url: `/bookmark/add/${id}`,
  });
  return response;
});

export const deleteBookmark = createAsyncThunk(
  'bookmark/remove',
  async (id, { extra: httpService }) => {
    const response = await httpService.post({
      url: `/bookmark/delete/${id}`,
    });
    return response;
  },
);
