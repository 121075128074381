import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import Button from 'components/Button';
import Image from 'components/Image';
import Auth from 'components/Header/Auth';
import Discussion from './Discussion';
import { Typography } from 'ui/Typography';

import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const PopularDiscussions = ({
  className,
  theme,
  title,
  join_title,
  join_description,
  image,
  items,
}) => {
  const { elementClick } = useAnalytics();
  const handleItemClick = useCallback(() => {
    elementClick('80lv_site_popular-discussions_click');
  }, [elementClick]);

  return (
    <div
      className={cn(styles.PopularDiscussions, className, {
        [styles[`PopularDiscussions_theme_${theme}`]]: theme,
      })}
    >
      <div className={styles.PopularDiscussions__header}>
        <Typography.Heading level="5" className={styles.PopularDiscussions__headerTitle}>
          {title}
        </Typography.Heading>

        {image ? (
          <div className={styles.PopularDiscussions__headerIcon}>
            <Image image={image} isIcon />
          </div>
        ) : (
          <img
            src="/static/icons/comments/typing.gif"
            alt="message box"
            className={styles.PopularDiscussions__headerIcon}
          />
        )}
      </div>

      <ul className={styles.PopularDiscussions__list}>
        {items.map(discussion => (
          <li
            key={discussion.id}
            className={styles.PopularDiscussions__listItem}
            onClick={handleItemClick}
          >
            <Discussion className={styles.PopularDiscussions__discussion} {...discussion} />
          </li>
        ))}
      </ul>

      <div className={styles.PopularDiscussions__footer}>
        <Typography.Heading className={styles.PopularDiscussions__footerTitle} level="6">
          {join_title}
        </Typography.Heading>

        <p className={styles.PopularDiscussions__footerText}>{join_description}</p>

        <Auth logoutButton={false}>
          <div className={cn(styles.PopularDiscussions__footerActions, 'xl-login')}>
            <Button
              className={styles.PopularDiscussions__footerButton}
              theme="none"
              size="medium"
              preset="primary"
            >
              Sign up
            </Button>
            <Button
              className={styles.PopularDiscussions__footerButton}
              theme="none"
              size="medium"
              preset="secondary"
              withArrow
            >
              Log in
            </Button>
          </div>
        </Auth>
      </div>
    </div>
  );
};

PopularDiscussions.defaultProps = {
  className: '',
};

PopularDiscussions.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  join_title: PropTypes.string,
  join_description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.array,
};

export default PopularDiscussions;
