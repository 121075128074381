import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames/bind';

// Components
import Page from 'components/Page';
import Container from 'components/Container';
import OurPartners from 'components/OurPartners';

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

function HomePartners() {
  const partners = useSelector(state => state.home.partners);

  return !partners ? null : (
    <Container>
      <Page.Section title={partners.title} className={cx('HomePartners')}>
        <OurPartners {...partners} />
      </Page.Section>
    </Container>
  );
}

export default HomePartners;
