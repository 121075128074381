import React, { Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Slider from 'components/Slider';

import styles from './styles.styl';
import MovingArrow from 'components/MovingArrow';
import Button from 'components/Button';

const ScrollbarSlider = ({
  children,
  title,
  subtitle,
  className,
  actions,
  containerClass,
  wrapperClass,
  navigation = true,
  overflow,
}) => {
  const swiperRef = useRef(null);
  const scrollbarContainerRef = useRef(null);
  const scrollbarRef = useRef(null);
  // State variables to track the loading status of nav and scrollbar elements
  const [isNavLoaded, setIsNavLoaded] = useState(false);
  const [isScrollbarLoaded, setIsScrollbarLoaded] = useState(false);

  useEffect(() => {
    // Exit if we haven't loaded the nav and scrollbar elements yet
    if (!scrollbarContainerRef.current || !scrollbarRef.current) return;
    // Append the scrollbar element to the nav element in the DOM
    scrollbarContainerRef.current.append(scrollbarRef.current);
    // Update the size of the scrollbar because it doesn't know it was moved
    swiperRef.current.scrollbar.updateSize();
  }, [isNavLoaded, isScrollbarLoaded]);

  const handleGetSwiperInstance = useCallback(swiper => {
    swiperRef.current = swiper;
  }, []);
  const handleScrollbarContainerRef = useCallback(el => {
    if (el) setIsNavLoaded(true);
    scrollbarContainerRef.current = el;
  }, []);
  const handleScrollbarRef = useCallback(el => {
    if (el) setIsScrollbarLoaded(true);
    scrollbarRef.current = el;
  }, []);

  // Handle pagination buttons
  const handlePrev = useCallback(() => {
    swiperRef.current.slidePrev();
  }, []);
  const handleNext = useCallback(() => {
    swiperRef.current.slideNext();
  }, []);
  // Custom scrollbar render
  const renderScrollbar = useCallback(
    () => <div className={styles.ScrollbarSlider__scrollbar} ref={handleScrollbarRef} />,
    [handleScrollbarRef],
  );

  const sliderNavigationProps = useMemo(() => {
    if (!navigation) return {};

    return {
      navigation: {
        prevEl: `.${styles.ScrollbarSlider__navButtonPrev}`,
        nextEl: `.${styles.ScrollbarSlider__navButtonNext}`,
      },
      scrollbar: {
        el: `.${styles.ScrollbarSlider__scrollbar}`,
        draggable: true,
        snapOnRelease: false,
      },
      getSwiper: handleGetSwiperInstance,
      renderScrollbar,
    };
  }, [handleGetSwiperInstance, navigation, renderScrollbar]);

  return (
    <div
      className={cn(
        styles.ScrollbarSlider,
        { [styles.ScrollbarSlider_overflow]: overflow },
        className,
      )}
    >
      <div className={styles.ScrollbarSlider__header}>
        <div className={styles.ScrollbarSlider__headerInfo}>
          <h2 className={styles.ScrollbarSlider__title}>{title}</h2>
          <p className={styles.ScrollbarSlider__subtitle}>{subtitle}</p>
        </div>
        {actions && <div className={styles.ScrollbarSlider__headerActions}>{actions}</div>}
      </div>
      <Slider
        containerClass={cn(
          'swiper-container',
          { [styles.ScrollbarSlider__container_overflow]: overflow },
          containerClass,
        )}
        wrapperClass={wrapperClass}
        className={styles.ScrollbarSlider__slider}
        slidesPerView="auto"
        {...sliderNavigationProps}
      >
        {Children.map(children, childSlide => (
          <div className={styles.ScrollbarSlider__slide} key={childSlide.key}>
            {childSlide}
          </div>
        ))}
      </Slider>
      {navigation && (
        <div className={styles.ScrollbarSlider__nav}>
          <div className={styles.ScrollbarSlider__buttons}>
            <MovingArrow.Wrapper>
              <Button
                theme="none"
                className={cn(
                  styles.ScrollbarSlider__navButton,
                  styles.ScrollbarSlider__navButtonPrev,
                )}
                onClick={handlePrev}
              >
                <MovingArrow />
              </Button>
            </MovingArrow.Wrapper>
            <MovingArrow.Wrapper>
              <Button
                theme="none"
                className={cn(
                  styles.ScrollbarSlider__navButton,
                  styles.ScrollbarSlider__navButtonNext,
                )}
                onClick={handleNext}
              >
                <MovingArrow />
              </Button>
            </MovingArrow.Wrapper>
          </div>
          <div
            className={styles.ScrollbarSlider__scrollbarContainer}
            ref={handleScrollbarContainerRef}
          />
        </div>
      )}
    </div>
  );
};

ScrollbarSlider.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  actions: PropTypes.node,
  containerClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  navigation: PropTypes.bool,
  overflow: PropTypes.bool,
};

export default ScrollbarSlider;
