import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import htmlParser from 'html-react-parser';

// Components
import SliderWidget from 'components/SliderWidget';
import AppLink from 'components/AppLink';
import Button from 'components/Button';
import Image from 'components/Image';

import styles from './styles.styl';
import useAnalytics from 'hooks/useAnalytics';

const VendorsSlider = ({ className, title, action, items, actionName }) => {
  const { elementClick } = useAnalytics();
  const handleGoToVendors = useCallback(() => {
    elementClick('80lv_site_vendors_click', 'all', { value: 'sidebar' });
  }, [elementClick]);

  const handleItemClick = useCallback(() => {
    elementClick('80lv_site_vendors_click', 'one', { value: 'sidebar' });
  }, [elementClick]);

  return (
    <div className={cn(styles.VendorsSlider, className)}>
      <SliderWidget className={styles.VendorsSlider__slider} title={title}>
        {items.map((item, key) => (
          <div key={key} className={styles.VendorsSlider__slide}>
            <figure className={styles.VendorsSlider__slideImg}>
              <AppLink href={`/partners/${item.slug}`} onClick={handleItemClick}>
                <Image image={item.image} />
              </AppLink>
            </figure>
            <div className={styles.VendorsSlider__slideContent}>
              <div className={styles.VendorsSlider__slideDescription}>
                {htmlParser(item.description)}
              </div>
            </div>
          </div>
        ))}
      </SliderWidget>

      <Button
        href="/partners"
        className={styles.VendorsSlider__button}
        theme="none"
        preset="primary"
        size="medium"
        withArrow
        onClick={handleGoToVendors}
      >
        {action || actionName}
      </Button>
    </div>
  );
};

VendorsSlider.DefaultProps = {
  className: '',
};

VendorsSlider.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  items: PropTypes.array,
  link: PropTypes.string,
  actionName: PropTypes.string,
};

export default VendorsSlider;
