// Vendor
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

// Components
import Slider from 'components/Slider';
import Avatar from 'components/Avatar';
import Heading from 'components/Heading';
import Button from 'components/Button';
import PopularThisWeekCard from './PopularThisWeekCard';
import BlockLabel from 'ui/BlockLabel';
import SubscribeToNewsletterButton from 'components/SubscribeToNewsletterButton';
import AppLink from 'components/AppLink';

import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const PopularThisWeek = ({ className, isVerticalSmallTablet, title, action, items }) => {
  const { elementClick } = useAnalytics();
  const handleGoToPopular = useCallback(() => {
    elementClick('80lv_site_popular-this-week_click', 'all');
  }, [elementClick]);

  const handleItemClick = useCallback(() => {
    elementClick('80lv_site_popular-this-week_click', 'one');
  }, [elementClick]);

  const renderCards = useCallback(
    () =>
      items.map(topic => (
        <div key={topic.id} className={styles.PopularThisWeek__topicsItem}>
          <PopularThisWeekCard
            {...topic}
            className={styles.PopularThisWeek__topic}
            onClick={handleItemClick}
          />
        </div>
      )),
    [handleItemClick, items],
  );

  return (
    <div className={cn(styles.PopularThisWeek, className)}>
      <BlockLabel className={styles.PopularThisWeek__label} onClick={handleGoToPopular}>
        <AppLink
          href={`/articles/?page=1&sort=popular`}
          as={'/articles'}
          onClick={handleGoToPopular}
        >
          <Avatar
            border
            cancelDefault
            borderColor="#fff"
            className={styles.PopularThisWeek__avatar}
            image="/static/images/home/PopularThisWeek/rock.svg"
          />
        </AppLink>
      </BlockLabel>
      <Heading className={styles.PopularThisWeek__title} level={1}>
        <AppLink
          href={`/articles/?page=1&sort=popular`}
          as={`/articles`}
          onClick={handleGoToPopular}
        >
          {title}
        </AppLink>
      </Heading>

      {!items.length ? null : isVerticalSmallTablet ? (
        <Slider
          className={styles.PopularThisWeek__topics}
          slideClass={styles.PopularThisWeek__slide}
          slidesPerView="auto"
        >
          {renderCards()}
        </Slider>
      ) : (
        <ul className={styles.PopularThisWeek__topics}>{renderCards()}</ul>
      )}

      <Button
        href="/articles/?page=1&sort=popular"
        className={styles.PopularThisWeek__button}
        withArrow
        onClick={handleGoToPopular}
      >
        {action}
      </Button>

      <SubscribeToNewsletterButton className={styles.PopularThisWeek__button} block="popular" />
    </div>
  );
};

PopularThisWeek.propTypes = {
  className: PropTypes.string,
  isVerticalSmallTablet: PropTypes.bool.isRequired,
  title: PropTypes.string,
  action: PropTypes.string,
  items: PropTypes.array,
};

PopularThisWeek.defaultProps = {
  items: [],
  className: '',
};

const connector = connect(({ responsive }) => ({
  isVerticalSmallTablet: responsive.isVerticalSmallTablet,
}));

export default connector(PopularThisWeek);
